import request from "@/utils/request";

// 结转损益
export function profitAndLoss(data) {
  return request({
    url: "api/v2/carryover/profitAndLoss",
    method: "post",
    data,
  });
}
// 结转资产
export function carryOverAsset(data) {
  return request({
    url: "api/v2/carryover/carryOverAsset",
    method: "post",
    data,
  });
}
// 结转工资
export function salaryCarryOver(data) {
  return request({
    url: "api/v2/carryover/salaryCarryOver",
    method: "post",
    data,
  });
}

//结账
export function checkOut(data) {
  return request({
    url: "api/v2/carryover/checkOut",
    method: "post",
    data,
  });
}

//反结账
export function reverseCheckout(data) {
  return request({
    url: "api/v2/carryover/reverseCheckout",
    method: "post",
    data,
  });
}

//结转汇兑损益
export function voucherFx(data) {
  return request({
    url: "api/v2/carryover/voucherFx",
    method: "post",
    data,
  });
}

//init
export function carrOverList(data) {
  return request({
    url: "api/v2/carryover/carrOverList",
    method: "post",
    data,
  });
}

export function carryOverOne(data) {
  return request({
    url: "api/v2/carryover/carryOverOne",
    method: "post",
    data,
  });
}
//同步科目余额表--正常账期
export function linkAgeAll(data) {
  return request({
    url: "api/v2/carryover/linkAgeAll",
    method: "post",
    data,
  });
}

//结账的账期list
export function getPeriods(data) {
  return request({
    url: "api/v2/carryover/getPeriods",
    method: "post",
    data,
  });
}

//外币汇率调整
export function wbVoucherFxList(data) {
  return request({
    url: "api/v2/carryover/wbVoucherFxList",
    method: "post",
    data,
  });
}

//保存
export function wbVoucherFx(data) {
  return request({
    url: "api/v2/carryover/wbVoucherFx",
    method: "post",
    data,
  });
}

//结账页面
export function getCarryOverInfo(data) {
  return request({
    url: "api/v2/carryover/getCarryOverInfo",
    method: "post",
    data,
  });
}

//查看结转科目
export function getComSetJz(data) {
  return request({
    url: "api/v2/carryover/getComSetJz",
    method: "post",
    data,
  });
}

//修改结转科目
export function setComSetJz(data) {
  return request({
    url: "api/v2/carryover/setComSetJz",
    method: "post",
    data,
  });
}

//生成凭证
export function jzSubject(data) {
  return request({
    url: "api/v2/carryover/jzSubject",
    method: "post",
    data,
  });
}
//生产核算生成凭证
export function zdProduceCheck(data) {
  return request({
    url: "api/v2/carryover/zdProduceCheck",
    method: "post",
    data,
  });
}
//贸易核算生成凭证
export function zdTradeCheck(data) {
  return request({
    url: "api/v2/carryover/zdTradeCheck",
    method: "post",
    data,
  });
}

//获取未结账公司数量接口
export function getUnclosed(data) {
  return request({
    url: "api/v2/carryover/getUnclosed",
    method: "post",
    data,
  });
}

//结账获取账期list
export function getCheckOutPeriod(data) {
  return request({
    url: "api/v2/carryover/getCheckOutPeriod",
    method: "post",
    data,
  });
}

//本期无需申报
export function getNoTax(data) {
  return request({
    url: "api/v2/carryover/getNoTax",
    method: "post",
    data,
  });
}

//本期无需申报导出
export function getNoTaxExport(data) {
  return request({
    url: "api/v2/carryover/getNoTaxExport",
    method: "post",
    data,
  });
}