<template>
  <div class="right_box">
    <el-dropdown
      size="small"
      @command="daochuHandleCommand"
      style="margin-left: 10px"
      :hide-on-click="false"
      v-if="$buttonStatus('dzzb_dc')"
    >
      <el-button size="small" type="success">
        导出<el-icon class="el-icon--right"><arrow-down /></el-icon>
      </el-button>
      <template #dropdown>
        <el-dropdown-menu style="text-align: center">
          <el-dropdown-item command="a">
            <span>凭证导出</span>
          </el-dropdown-item>
          <el-dropdown-item command="b">
            <span>科目余额表导出</span>
          </el-dropdown-item>
          <el-dropdown-item command="c">
            <span>工资表导出</span>
          </el-dropdown-item>
          <el-dropdown-item command="d">
            <span>凭证汇总导出</span>
          </el-dropdown-item>
          <el-dropdown-item command="e">
            <span>会计报表导出</span>
          </el-dropdown-item>
          <el-dropdown-item command="f">
            <span>总账导出</span>
          </el-dropdown-item>
          <el-dropdown-item command="g">
            <span>明细账导出</span>
          </el-dropdown-item>
          <el-dropdown-item command="h">
            <span>税务报表导出</span>
          </el-dropdown-item>
        </el-dropdown-menu>
      </template>
    </el-dropdown>
    <el-dropdown
      size="small"
      @command="handleCommand"
      style="margin-left: 10px; margin-right: 10px"
      :hide-on-click="false"
      v-if="$buttonStatus('dzzb_dy')"
    >
      <el-button size="small" type="primary">
        打印<el-icon class="el-icon--right"><arrow-down /></el-icon>
      </el-button>
      <template #dropdown>
        <el-dropdown-menu style="text-align: center">
          <el-dropdown-item command="a">
            <span>凭证打印</span>
          </el-dropdown-item>
          <el-dropdown-item command="b">
            <span>科目余额表打印</span>
          </el-dropdown-item>
          <el-dropdown-item command="f">
            <span>工资表打印</span>
          </el-dropdown-item>
          <el-dropdown-item command="g">
            <span>凭证汇总打印</span>
          </el-dropdown-item>
          <el-dropdown-item command="k">
            <span>会计报表打印</span>
          </el-dropdown-item>
          <el-dropdown-item command="s">
            <span>税务报表打印</span>
          </el-dropdown-item>
          <el-dropdown-item command="x">
            <span>总账打印</span>
          </el-dropdown-item>
          <el-dropdown-item command="y">
            <span>明细账打印</span>
          </el-dropdown-item>
          <el-dropdown-item command="z">
            <span>电子账打印</span>
          </el-dropdown-item>
        </el-dropdown-menu>
      </template>
    </el-dropdown>
    <el-button size="small" type="primary" plain @click="allotEditStatus" :disabled="!$buttonStatus('dzzb_xgzdzt')"
      >修改装订状态</el-button
    >
  </div>
  <el-table
    stripe
    :data="list"
    :height="contentStyleObj"
    style="width: 100%"
    border
    @select="handleSelectionChange"
    v-loading="loading"
    @sort-change="sortChange"
    ref="tableScroll"
    id="tableLazyLoad"
    @select-all="handleSelectionChangeAll"
  >
    <template #empty>
      <el-empty :image-size="150" description="没有数据"></el-empty>
    </template>
    <el-table-column align="center" prop="id" type="selection" width="55" />
    <el-table-column
      label="编号"
      align="center"
      prop="sort"
      width="80"
      fixed="left"
      sortable="custom"
    >
      <template #default="scope">
        <TableSortCell :row="scope.row" idKey="comId" />
      </template>
    </el-table-column>
    <el-table-column prop="name" fixed="left" label="公司名称" min-width="240">
      <template #default="scope">
        <TagNameCopy :row="scope.row"  idKey="comId" :showAuthIcon="false"></TagNameCopy>
      </template>
    </el-table-column>
    <el-table-column  prop="district" label="税局" width="65">
      <template #default="scope">
        <span>{{ $cityFilter(scope.row.district)  }}</span>  
      </template>
    </el-table-column>
    <el-table-column prop="accountSystem" label="会计制度" width="228">
      <template #default="scope">
        {{$accountSystemFilter(scope.row.accountSystem)}}
      </template>
    </el-table-column>
    <el-table-column
      align="center"
      prop="CurPeriod"
      label="当前账期"
      width="82"
    >
      <template #default="scope">
        <span>{{ scope.row.period }}</span>
      </template>
    </el-table-column>
    <el-table-column
      align="center"
      prop="CurPeriod"
      label="本期凭证数"
      width="85"
    >
      <template #default="scope">
        <span>{{ scope.row.Count }}</span>
      </template>
    </el-table-column>
    <el-table-column prop="settleStatus" label="结账状态" width="105">
      <template #default="scope">
        <el-tooltip
          class="box-item"
          effect="dark"
          placement="top-start"
          v-if="scope.row.reason && scope.row.settleStatus != 4"
        >
          <template #content>
            <el-scrollbar :maxHeight="350">
              <div v-html="scope.row.reason"></div>
            </el-scrollbar>
          </template>
          <div>
            <div
              class="item_icon"
              style="display: contents"
              v-if="scope.row.settleStatus == '2'"
            >
              <i class="iconfont icon-cuowu"></i>
              <p>
                {{ settleStatusfifle(scope.row.settleStatus) }}
                <i class="iconfont icon-wenhao" style="color: #f56c6c"></i>
              </p>
            </div>
            <div
              class="item_icon"
              style="display: contents"
              v-else-if="scope.row.settleStatus == '4'"
            >
              <i class="iconfont icon-duihao"></i>
              <p>{{ settleStatusfifle(scope.row.settleStatus) }}</p>
            </div>
            <div class="item_icon" style="display: contents" v-else>
              <i class="iconfont icon-gantanhao"></i>
              <p>{{ settleStatusfifle(scope.row.settleStatus) }}</p>
            </div>
          </div>
        </el-tooltip>
        <div v-else>
          <div
            class="item_icon"
            style="display: contents"
            v-if="scope.row.settleStatus == '2'"
          >
            <i class="iconfont icon-cuowu"></i>
            <p>{{ settleStatusfifle(scope.row.settleStatus) }}</p>
          </div>
          <div
            class="item_icon"
            style="display: contents"
            v-else-if="scope.row.settleStatus == '4'"
          >
            <i class="iconfont icon-duihao"></i>
            <p>{{ settleStatusfifle(scope.row.settleStatus) }}</p>
          </div>
          <div class="item_icon" style="display: contents" v-else>
            <i class="iconfont icon-gantanhao"></i>
            <p>{{ settleStatusfifle(scope.row.settleStatus) }}</p>
          </div>
        </div>
      </template>
    </el-table-column>
    <el-table-column align="center" prop="peoples" label="会计" width="60">
      <template #default="scope" >
        <div>
          <el-popover placement="bottom" :width="330" trigger="click">
            <template #reference>
              <div @click="poper(scope.row)" style="cursor:pointer;">
                <i class="iconfont icon-huiji"></i>
              </div>
            </template>
            <el-table :data="gridData" >
              <el-table-column width="100" align="center" property="cnName" label="负责人" />
              <el-table-column width="100" align="center" property="roleName" label="角色名称">
                <template #default="scope">
                  <img
                    src="@/assets/tu/guan.png"
                    alt=""
                    v-if="scope.row.isLeader == 1"
                    style="width: 15px;height: 15px;margin-right: 5px;vertical-align: middle;"
                  />
                  <span style=" display: inline; vertical-align: middle;">{{ scope.row.roleName }}</span>
                </template>
              </el-table-column>
              <el-table-column width="100" align="center" property="deptName" label="部门名称" />
            </el-table>
          </el-popover>
        </div>
      </template>
    </el-table-column>
    <el-table-column align="center" prop="auditUser" label="审核人" width="90">
      <template #default="scope">
        <span v-if="scope.row.auditUser">{{ scope.row.auditUser }}</span>
        <div class="item_icon" v-else>
          <i class="iconfont icon-gantanhao"></i>
          <p>未审核</p>
        </div>
      </template>
    </el-table-column>

    <el-table-column  label="电子账生成" width="210">
      <template #default="scope">
        <el-tooltip effect="dark" placement="top" :disabled="!scope.row.dzzErr || scope.row.dzzStatus != 3">
          <template #content>
            <div v-html="scope.row.dzzErr && (scope.row.dzzErr.match('115.28.130.221') || scope.row.dzzErr.match('124.129.235.10')) ? '当前打印任务队列繁忙，请稍后重试' : scope.row.dzzErr"> </div>
          </template>
          <div class="item_icon">
            <i :class="filterIcon(scope.row.dzzStatus)"></i>
            <p>{{ filterStatus(scope.row.dzzStatus) }}</p>
            <i class="iconfont icon-wenhao" style="color:red;margin-left: 3px;" v-if="scope.row.dzzErr && scope.row.dzzStatus == 3">
            </i>
          </div>
        </el-tooltip>
        <el-button
          size="small"
          type="text"
          @click="createVoucher(scope.row)"
          style="margin: 0"
          :disabled="scope.row.dzzbStatus == 1 || !$buttonStatus('dzzb_scdzzb')"
          >{{ scope.row?.dzzStatus == 2 ? '重新生成' : scope.row?.dzzbStatus == 1 ? '生成中':'生成' }}</el-button
        >
        <el-button
          size="small"
          type="text"
          @click="checkVoucher2(scope.row)"
          :disabled="scope.row.dzzStatus != 2 || !$buttonStatus('dzzb_ckdzzb')"
          style="margin: 0"
          >查看</el-button
        >
      </template>
    </el-table-column>
    <el-table-column  label="账簿生成状态" width="120">
      <template #default="scope">
        <el-tooltip effect="dark" placement="top" :disabled="!scope.row.dzzbErr || scope.row.dzzbStatus != 3">
          <template #content>
            <div v-html="scope.row.dzzbErr && (scope.row.dzzbErr.match('115.28.130.221') || scope.row.dzzbErr.match('124.129.235.10')) ? '当前打印任务队列繁忙，请稍后重试' : scope.row.dzzbErr"> </div>
          </template>
          <div class="item_icon">
            <i :class="filterIcon(scope.row.dzzbStatus)"></i>
            <p>{{ filterStatus(scope.row.dzzbStatus) }}</p>
            <i class="iconfont icon-wenhao" style="color:red;margin-left: 3px;" v-if="scope.row.dzzbErr && scope.row.dzzbStatus == 3">
            </i>
          </div>
        </el-tooltip>
      </template>
    </el-table-column>
    <el-table-column prop="CurPeriod" label="装订状态" width="105">
      <template #default="scope">
        <div class="item_icon" v-if="scope.row.bindingStatus == 0">
          <i class="iconfont icon-gantanhao"></i>
          <p>未装订</p>
        </div>
        <div class="item_icon" v-if="scope.row.bindingStatus == 1">
          <i class="iconfont icon-duihao"></i>
          <p>已装订</p>
        </div>
        <i
          class="iconfont icon-bianji"
          style="cursor: pointer;font-size: 13px;margin-left:8px"
          @click="editStatus(scope.row)"
          v-if="$buttonStatus('dzzb_xgzdzt')"
        ></i>
      </template>
    </el-table-column>
    <el-table-column label="随手记" min-width="120" v-if="$buttonStatus('jzkh_ssj')" show-overflow-tooltip>
      <template #default="scope">
        <span class="xgmStyle" style="cursor:pointer;" @click="smallNotes(scope.row)">记</span>
        <span>{{scope.row.jotDown}}</span>
      </template>
    </el-table-column>
  </el-table>
  <div style="float: left; margin-top: 16px">
    <el-button
      size="small"
      type="primary"
      plain
      icon="Checked"
      @click="updateUserAll"
      :disabled="!$buttonStatus('dzzb_yjsh')"
      >一键审核</el-button
    >
  </div>
  <div class="pagination">
    <qzf-pagination
      v-show="total > 0"
      :total="total"
      v-model:page="listQuery.page"
      v-model:limit="listQuery.limit"
      @pagination="getList"
      type="eletricBook"
    />
  </div>
  <!-- 修改装订状态 -->
  <el-dialog
    :close-on-click-modal="false"
    v-model="dialogStatusVisible"
    title="修改装订状态"
    width="40%"
  >
    <el-form>
      <el-form-item label="装订状态:">
        <div>
          <el-radio :label="0" border size="small" v-model="temp.bindingStatus"
            >未装订</el-radio
          >
          <el-radio :label="1" border size="small" v-model="temp.bindingStatus"
            >已装订</el-radio
          >
        </div>
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button size="small" @click="dialogStatusVisible = false"
          >取消</el-button
        >
        <el-button size="small" type="primary" @click="updateStatus"
          >确定</el-button
        >
      </span>
    </template>
  </el-dialog>
  <!-- 生成电子账本 -->
  <el-dialog
    :close-on-click-modal="false"
    v-model="dialogCreateVisible"
    title="账本生成"
    width="660px"
  >
    <el-form label-width="90px">
      <el-form-item label="会计所属期:">
        <div>
          <el-date-picker
            v-model="voucherForm.startPeriod"
            type="month"
            placeholder="选择月"
            size="small"
            value-format="YYYYMM"
            style="width: 110px"
          >
          </el-date-picker>
          <!-- --
          <el-date-picker
            v-model="voucherForm.endPeriod"
            type="month"
            placeholder="选择月"
            size="small"
            value-format="YYYYMM"
            style="width: 110px"
          >
          </el-date-picker> -->
        </div>
      </el-form-item>
      <el-form-item  label="封面类型:">
        <div>
          <el-radio-group v-model="voucherForm.faceType" @change="faceTypeChange(voucherForm.faceType)">
            <el-radio :label="1">横版</el-radio>
            <el-radio :label="2">竖版</el-radio>
          </el-radio-group>
        </div>
      </el-form-item>
      <el-form-item label="纸张类型:">
        <el-radio-group v-model="voucherForm.paperType" size="small">
          <el-radio label="A5-H" border>凭证纸</el-radio>
          <el-radio label="A4-S" border>A4纸-2</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="选项:">
         <el-checkbox
            style="margin-right:15px"
            v-model="checkAccountAll"
            :indeterminate="accountChecked"
            @change="handleAccountCheckAllChange"
            >全选</el-checkbox
          >
          <el-checkbox-group v-model="chooseBook" @change="handleAccountCheckedChange">
            <el-checkbox v-for="item in accountOptions" :label="item.label" :key="item.label" :disabled="item.disabled">{{item.label}}</el-checkbox>
          </el-checkbox-group>
      </el-form-item>
      <el-form-item label="显示条数：">
        <el-input v-model.number="voucherForm.num" placeholder="请输入显示条数" size="small" style="width:110px"></el-input>
      </el-form-item>
      <el-form-item  label="科目余额表是否只显示有金额科目:" label-width="220px" v-if="chooseBook.includes('科目余额表')">
       <el-switch v-model="voucherForm.not0" size="small" /> 
        </el-form-item>
      <el-form-item label="附件:">
        <el-checkbox
          style="margin-right:15px"
          v-model="checkFjAll"
          :indeterminate="fjChecked"
          @change="handleFjCheckAllChange"
          >全选</el-checkbox
        >
        <el-checkbox-group v-model="voucherForm.fj" @change="handlefjCheckedChange">
          <el-checkbox v-for="city in fjOptions" :label="city" :key="city">{{city}}</el-checkbox>
        </el-checkbox-group>
      </el-form-item>
    
      <el-form-item label="屏蔽:" class="item_row">
        <el-checkbox-group v-model="checkList">
          <el-checkbox label="屏蔽参考信息"></el-checkbox>
          <el-checkbox label="屏蔽业务日期"></el-checkbox>
          <el-checkbox label="屏蔽序号"></el-checkbox>
        </el-checkbox-group>
      </el-form-item>
      <el-form-item label="封面颜色:" class="item_row">
        <el-radio-group v-model="voucherForm.fmColor">
          <el-radio :label="0">白色</el-radio>
          <el-radio :label="1">牛皮纸色</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="生成类型:" class="item_row">
        <el-radio-group v-model="voucherForm.elecType ">
          <el-radio label="电子账">电子账（簿）</el-radio>
          <!-- <el-radio label="电子帐簿">电子帐簿</el-radio> -->
        </el-radio-group>
        <el-tooltip placement="top">
          <template #content>
            <div>
              <p>生成电子账之后，电子账簿同时生成</p>
            </div>
          </template>
          <i class="iconfont icon-wenhao" style="color:red;cursor: pointer;font-size:13px;margin-left:14px"></i>
        </el-tooltip>
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button size="small" @click="dialogCreateVisible = false"
          >取消</el-button
        >
        <el-button
          size="small"
          type="primary"
          @click="createSure"
          :loading="vouLoading"
          >确定</el-button
        >
      </span>
    </template>
  </el-dialog>
  <printVouchPz
    ref="printVouchPz"
    :listIds="allselect"
    name="book_voucher"
    :listQuery1="this.listQuery"
    :datePeriod="true"
  ></printVouchPz>
  <printVouch
    ref="printVouch"
    :name="printName"
    :listQuery1="this.listQuery"
    :datePeriod="true"
    from="zpy"
  ></printVouch>
  <div class="dialog_div">
    <el-dialog
      v-model="dialogCheckVisible"
      :style="
        {width:'1400px',
        height: currentRow?.paperType === 'A4-S' ? '900px' : '450px',
        'background-color': 'inherit',
        'box-shadow': 'none',}
      "
      custom-class="no-header-dialog"
      :show-close="false"
      class="dialog"
      :before-close="bookCloseClearRow"
    >
      <div style="">
        <div style="display: flex">
          <iframe
            style="position: relative"
            :src="voucherUrl"
            frameborder="0"
            id="mainiframe"
            width="100%"
            :height="currentRow?.paperType === 'A4-S' ? '900px' : '450px'"
            border="0"
          >
          </iframe>
          <el-icon :size="30" color="white" @click="close"
            ><CircleClose
          /></el-icon>
        </div>
        <div style="display: flex; justify-content: center">
          <div class="btn_button_view">
            <el-button
              type=""
              style="width: 65px;border: 1px solid #fff;background: black;color:white;height: 23px;"
              @click="copy"
              >分享</el-button
            >
            <el-button
              type=""
              style="width: 65px;border: 1px solid #fff;background: black;color:white;height: 23px;"
              @click="createSure2"
              >打印</el-button
            >
            <el-button
              type=""
              style="width: 65px;border: 1px solid #fff;background: black;color:white;height: 23px;"
              @click="back_home"
              >封面</el-button
            >
          </div>
        </div>
      </div>
    </el-dialog>
    <el-dialog
      v-model="dzzPdfVisible"
      width="1000px"
      custom-class="no-header-dialog"
      class="dialog"
    >
      <div class="posi-rela dzz-pdf-dialog-content-container">
        <el-icon class="dzz-pdf-dialog-close" :size="20" color="white" @click="closeDzzPdfDialog"><CircleCloseFilled /></el-icon>
        <iframe
          style="position: relative"
          :src="dzzPdfUrl"
          frameborder="0"
          id="mainiframe"
          width="100%"
          height="600px"
          border="0"
        >
        </iframe>
        <div class="dzz-pdf-btn-box">
          <el-button
            size="small"
            type="primary"
            @click="checkVoucher(currentRow)"
            :disabled="currentRow?.dzzbStatus != 2 || !$buttonStatus('dzzb_ckdzzb')"
            style="margin: 0"
            >查看电子账簿</el-button
          >
          <el-button
            size="small"
            type="default"
            @click="closeDzzPdfDialog"
            style="margin: 0"
            >关闭</el-button
          >
        </div>
      </div>
    </el-dialog>
  </div>
  <jotDown ref="jotDown" @jotDownUpdate="updateNote"/>
  <qzf-export :name="mqType" ref="exportFile" :period="listQuery.period"/>
  <eleFinancePrint ref="eleFinancePrint" :period="listQuery.period"/>
  <eleEmployeePrint ref="eleEmployeePrint" :period="listQuery.period"/>
</template>

<script>
import {
  electronicBookList,
  updateBindingStatus,
  updateAuditUser,
  companyUserList
} from "@/api/company";
import { createVoucherImage,getVoucherImageByUuid } from "@/api/voucher";
import printVouchPz from "../../bookkeeps/components/printVouchPz.vue";
import printVouch from "../../bookkeeps/components/printVouch.vue";
import { bulkPrintMq,report,accountBook } from "@/api/printSet";
import { bulkExportMq } from "@/api/export";
import jotDown from '@/components/jotDown'
import TagNameCopy from "@/components/table-cell-render/company-name/TagNameCopy";
import TableSortCell from "@/components/table-cell-render/table-sort-cell/TableSortCell";
import eleFinancePrint from "@/components/printSetting/eleFinancePrint";
import eleEmployeePrint from "@/components/printSetting/eleEmployeePrint";
export default {
  name: "eletricTable",
  components: {
    printVouchPz,
    printVouch,
    jotDown,
    TagNameCopy,
    TableSortCell,
    eleFinancePrint,
    eleEmployeePrint
  },
  props: {
    tabName: String,
    listQuery: {},
  },
  data() {
    return {
      dzzPdfVisible: false,
      dzzPdfUrl: '',
      contentStyleObj: {},
      loading: false,
      list: [],
      total: 0,
      selects: [],
      allselect: [],
      dialogStatusVisible: false,
      temp: {
        comId: [],
        bindingStatus: 0,
      },
      dialogCreateVisible: false,
      voucherForm: {
        comId: null,
        startPeriod: "",
        endPeriod: "",
        choose:[],
        fj:[],
        fmColor:0,
        option:"",
        elecType:"电子账",
        num:5,
        paperType:'A5-H',
        faceType: 1
      },
      checkList:[],
      voucherForm2: {
        comId: "",
        startPeriod: "",
        endPeriod: "",
        print: 1,
      },
      vouLoading: false,
      dialogCheckVisible: false,
      voucherUrl: "",
      uuid: "",
      accountOptions:[
        {
          label:"凭证",
          disabled:true,
          checked:false
        },
        {
          label:"科目余额表",
          disabled:false,
          checked:false
        },
        {
          label:"凭证汇总",
          disabled:false,
          checked:false
        }
      ],
      fjOptions:['进项','销项','发票清单','费用报销单','银行','薪酬','资产','出入库','上传附件','上月电子税局完税证明','本月电子税局完税证明','上月个税完税证明','本月个税完税证明'],
      checkFjAll:false,
      fjChecked:false,
      checkAccountAll:false,
      accountChecked: false,
      top:0,
      chooseBook:['凭证'],
      totalPage:0,
      pageSize:50,
      tableData:[],
      currentPage:1,
      allChecked:false,
      gridData:[],//列表会计存放数据
      currentRow: null,
      mqType:"",
      printName:""
    };
  },
  mounted() {
    this.listQuery.limit = localStorage.getItem('eletricBook') ? localStorage.getItem('eletricBook')*1 : 20
    this.contentStyleObj = this.$getHeight(245);
    this.getList();
  },
  methods: {
    faceTypeChange(val){
      localStorage.setItem('dzFaceType',val)
    },
    // 列表点击
    poper(val){
      this.gridData = []
      companyUserList({comId:val.comId}).then(res=>{
        this.gridData = res.data.data.list
      })
    },
    copy() {
      //复制
      const coverColor = this.currentRow.cover ? 'rgb(2131,189,129)' : 'rgb(255,255,255)'
      let url = `https://file.listensoft.net/mnt/v2/html/jsp-turn/index.html?pdfUrl=${encodeURIComponent(this.currentRow.dzzPdfUrl)}&coverColor=${coverColor}&paperType=${this.currentRow.paperType}&from=share`
      this.$copyComName(url);
    },
    bookCloseClearRow () {
      this.voucherUrl = ''
      this.currentRow = null
    },
    close() {
      this.dialogCheckVisible = false;
    },
    //点击封面
    back_home() {
      if (!this.currentRow) return
      var iframe = document.getElementById("mainiframe");
      const coverColor = this.currentRow.cover ? 'rgb(2131,189,129)' : 'rgb(255,255,255)'
      this.voucherUrl = `https://file.listensoft.net/mnt/v2/html/jsp-turn/index.html?pdfUrl=${this.currentRow.dzzPdfUrl}&coverColor=${coverColor}&paperType=${this.currentRow.paperType}`
      iframe.src = this.voucherUrl;
    },
    settleStatusfifle(e) {
      if (e == "2") {
        return "结账失败";
      } else if (e == "4") {
        return "已结账";
      } else {
        return "未结账";
      }
    },
    getList() {
      this.loading = true;
      electronicBookList(this.listQuery).then((res) => {
        this.loading = false;
        this.tableData = res.data.data.list ? res.data.data.list : [];
        this.total = res.data.data.total;
        this.setScroll()
      });
    },
    setScroll(){
      //先重置状态和数组
      this.allChecked = false
      this.selects = []
      //计算滚动页数
      this.totalPage = this.tableData.length/this.pageSize
      if(this.tableData.length % this.pageSize == 0){
        this.totalPage = this.tableData.length/this.pageSize
      }else{
        this.totalPage = parseInt(this.tableData.length/this.pageSize) + 1
      }
      this.list = this.tableData.slice(0,this.currentPage*this.pageSize)
      this.lazyLoading()
    },
    lazyLoading(){
      let lazy = document.getElementById("tableLazyLoad");
      let dom = lazy.querySelector(".el-scrollbar__wrap");
      let that = this
      dom.addEventListener("scroll", function () {
        const scrollDistance = dom.scrollHeight - dom.scrollTop - dom.clientHeight;
        if (scrollDistance <= 5) {
          if (that.currentPage < that.totalPage) {
            that.currentPage++;
            that.list = that.tableData.slice(
              0,
              that.currentPage * that.pageSize
            );
            if(that.allChecked && that.selects.length == that.tableData.length){
              that.$refs.tableScroll.toggleAllSelection()
              that.allChecked = true
            }else if(that.selects.length != 0){
              that.selects.map(v=>{
                that.list.map(item=>{
                  if(item.comId == v.comId){
                    console.log(item.comId);
                    that.$nextTick(()=>{
                     that.$refs.tableScroll.toggleRowSelection(item,true)
                    })
                  }
                })
              })
            }
          }
        }
      });
    },
    // 表格排序
    sortChange(data) {
      this.listQuery.desc = data.order == "descending" ? 1 : 0;
      this.getList();
    },
    //更新随手记之后的回调
    updateNote(id,text){
      this.list.map(v=>{
        if(v.comId == id){
          v.jotDown = text
        }
      })
    },
    handleSelectionChange(val) {
      if(this.allChecked && this.list.length != this.tableData.length){
        let currentIndex = 0
        this.list.map((v,i2)=>{
          val.map(e=>{
            if(e.comId == v.comId){
              currentIndex = i2
            }
          })
        })
        let arr = this.tableData.slice(currentIndex+1,this.tableData.length)
        let newArr = [...val,...arr]
        this.selects = newArr
      }else{
        this.selects = val;
      }
    },
    handleSelectionChangeAll(e){
      if(e.length != 0){
        this.selects = this.tableData
        this.allChecked = true
      }else{
        this.selects = []
        this.allChecked = false
      }
    },
    handleCommand(command) {
      if (this.selects.length == 0) {
        this.$qzfMessage("请选择一家公司", 2);
        return;
      }
      let comIds = [];
      this.selects.map((item) => {
        comIds.push(item.comId);
      });
      this.listQuery.optionType = "";
      if (command == "a") {
        this.$refs.printVouchPz.init(comIds);
      } else if (command == "b") {
        this.printName = 'book_subject_balance'
        this.$nextTick(()=>{
          this.$refs.printVouch.init(comIds);
        })
      } else if (command == "f") {
        this.$refs.eleEmployeePrint.init(comIds)
      } else if (command == "g") {
        this.printName = 'book_all_voucher'
        this.$nextTick(()=>{
          this.$refs.printVouch.init(comIds);
        })
      } else if (command == "k") {
        this.$refs.eleFinancePrint.init(this.selects,'print')
      } else if (command == "x") {
        this.printName = 'book_all_account'
        this.$nextTick(()=>{
          this.$refs.printVouch.init(comIds);
        })
      } else if (command == "y") {
        this.printName = 'book_detail_account'
        this.listQuery.optionType = "1";
        this.$nextTick(()=>{
          this.$refs.printVouch.init(comIds);
        })
      }else if (command == "s") {
        this.mqType = "swbb";
        this.$nextTick(()=>{
          this.$refs.exportFile.init(comIds,'print')
        })
      }else if (command == "z") {
        this.dialogCreateVisible = true;
        this.voucherForm.startPeriod = this.listQuery.period;
        this.voucherForm.comIds = comIds;
        this.voucherForm.period = this.listQuery.period
        this.voucherForm.bookNames = ["book_electronic_book"];
        if(localStorage.getItem("dzFaceType")){
            this.voucherForm.faceType = localStorage.getItem("dzFaceType")*1
        }else{
          this.voucherForm.faceType = 1
        }
      }
    },
    //导出
    daochuHandleCommand(command) {
      if (this.selects.length == 0) {
        this.$qzfMessage("请选择一家公司", 2);
        return;
      }
      let comIds = [];
      this.selects.map((item) => {
        comIds.push(item.comId);
      });
      if(command != 'e'){
        switch (command) {
          case "a":
            this.mqType = "book_voucher";
            break;
          case "b":
            this.mqType = "book_subject_balance";
            break;
          case "c":
            this.mqType = "salary";
            break;
          case "d":
            this.mqType = "book_all_voucher";
            break;
          case "f":
            this.mqType = "book_all_account";
            break;
          case "g":
            this.mqType = "book_detail_account";
            break;
          case "h":
            this.mqType = "swbb";
            break;
        }
        this.$nextTick(()=>{
          if(command == 'c'){
            this.$refs.exportFile.init(comIds,'salary')
          }else{
            this.$refs.exportFile.init(comIds)
          }
        })
      }else{
        this.$refs.eleFinancePrint.init(this.selects,'export')
      }
    },
    //修改装订状态
    editStatus(row) {
      this.dialogStatusVisible = true;
      this.temp.comId = [row.comId];
    },
    allotEditStatus() {
      if (this.selects.length == 0) {
        this.$qzfMessage("请选择公司", 1);
        return;
      }
      this.temp.comId = [];
      this.selects.map((v) => {
        this.temp.comId.push(v.comId);
      });
      this.dialogStatusVisible = true;
    },
    updateStatus() {
      this.temp.period = this.listQuery.period;
      updateBindingStatus(this.temp).then((res) => {
        if (res.data.msg == "success") {
          this.dialogStatusVisible = false;
          this.$qzfMessage("修改成功");
          this.getList();
        }
      });
    },
    //一键审核
    updateUserAll() {
      if (this.selects.length == 0) {
        this.$qzfMessage("请选择公司", 1);
        return;
      }
      let ids = [];
      this.selects.map((v) => {
        ids.push(v.comId);
      });
      let param = {
        comIds: ids,
        period: this.listQuery.period,
      };
      updateAuditUser(param).then((res) => {
        if (res.data.msg == "success") {
          this.$qzfMessage("审核成功");
          this.getList();
        }
      });
    },
    //备注
    smallNotes(row) {
      this.$refs.jotDown.init(row.name,row.comId,this.listQuery.period)
    },
    //生成电子账本
    createVoucher(row) {
      this.dialogCreateVisible = true;
      this.voucherForm.startPeriod = row.period;
      // this.voucherForm.endPeriod = row.period;
      this.voucherForm.comIds = [row.comId];
      this.voucherForm.period = this.listQuery.period
      this.voucherForm.bookNames = ["book_electronic_book"];
    },
    createSure() {
      if(this.voucherForm.num > 8){
        this.$qzfMessage('条数最大为8！',1);
        return
      }
      this.vouLoading = true;
      this.voucherForm.zip = this.voucherForm.comIds.length > 1 ? 1 : 0;
      this.voucherForm.endPeriod = this.voucherForm.startPeriod
      this.voucherForm.option = this.checkList.join('-')
      this.voucherForm.choose = this.chooseBook.filter(item => item != '凭证')
      accountBook(this.voucherForm).then(res=>{
        this.vouLoading = false
        this.dialogCreateVisible = false
        if(res.data.msg == 'success'){
          if (res.data.data.msg) {
           this.$qzfMessage(res.data.data.msg);
          } else {
            window.open(res.data.data.path);
          }
          this.getList()
        }
      })
      // createVoucherImage(this.voucherForm).then((res) => {
      //   this.vouLoading = false;
      //   if (res.data.msg == "success") {
      //     this.dialogCreateVisible = false;
      //     this.$qzfMessage(res.data.data);
      //     this.getList();
      //   }
      // });
    },
    createSure2() {
      if(this.currentRow.dzzPdfUrl){
        window.open("https://file.listensoft.net" + this.currentRow.dzzPdfUrl)
      }else{
        this.$qzfMessage('请重新生成账本',1)
      }
    },
    //查看电子账本
    checkVoucher(row) {
      //console.log(row);
      this.voucherForm2.comId = row.comId;
      const coverColor = row.cover ? 'rgb(2131,189,129)' : 'rgb(255,255,255)'
      this.voucherUrl = `https://file.listensoft.net/mnt/v2/html/jsp-turn/index.html?pdfUrl=${row.dzzPdfUrl}&coverColor=${coverColor}&paperType=${row.paperType}`
      // this.voucherUrl = `http://localhost:5500/?pdfUrl=${'https://file.listensoft.net' + row.dzzPdfUrl}&coverColor=${coverColor}&paperType=${row.paperType}`
      this.currentRow = row
      this.dialogCheckVisible = true;
      this.uuid = row.voucherImageUuid;
    },
    closeDzzPdfDialog() {
      this.dzzPdfVisible = false;
    },
    checkVoucher2(row){
      this.currentRow = row
      this.dzzPdfUrl ='https://file.listensoft.net' +row.dzzPdfUrl
      this.dzzPdfVisible = true;
      // window.open(url);
    },
    //电子账本选项
    handleAccountCheckAllChange(val) {
      let arr = []
      if(val){
        this.accountOptions.map(v=>{
          arr.push(v.label)
        })
        this.chooseBook = arr
      }else{
        this.chooseBook = ['凭证']
      }
      this.accountChecked = false;
    },
    handleAccountCheckedChange(value) {
      let checkedCount = value.length;
      this.checkAccountAll = checkedCount === this.accountOptions.length;
      this.accountChecked = checkedCount > 0 && checkedCount < this.accountOptions.length;
    },
    //电子账本附件
    handleFjCheckAllChange(val){
      this.voucherForm.fj = val? this.fjOptions : [];
      this.fjChecked = false;
    },
    handlefjCheckedChange(value){
      let checkedCount = value.length;
      this.checkFjAll = checkedCount === this.fjOptions.length;
      this.fjChecked = checkedCount > 0 && checkedCount < this.fjOptions.length;
    },
    filterIcon(status){
      if(status == 0 || status == ''){
        return "iconfont icon-gantanhao";
      }else if(status == 1){
        return 'iconfont icon-info'
      }else if(status == 2){
        return 'iconfont icon-duihao'
      }else if(status == 3){
        return 'iconfont icon-cuowu'
      }
    },
    filterStatus(status){
      if(status == 0 || status == ''){
        return "未生成";
      }else if(status == 1){
        return '生成中'
      }else if(status == 2){
        return '已生成'
      }else if(status == 3){
        return '生成失败'
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.right_box {
  position: absolute;
  right: 19px;
  top: 13px;
}
.item_icon {
  display: inline-block;
  cursor: pointer;
  i {
    display: inline-block;
    font-size: 13px;
    margin-right: 4px;
  }
  p {
    display: inline-block;
    font-size: 13px;
    color: #333;
  }
}
.el-select {
  margin-left: 0px;
}


:deep(.el-overlay) {
  background-color: rgba(0, 0, 0, 0.9) !important;
}
.el_icon {
  cursor: pointer;
  margin-top: 120px;
}
.btn_button_view {
  display: flex;
  justify-content: center;
  width: 500px;
}
.btn_button_view el-button {
  width: 70px;
  border: 2px solid #fff;
  background: black;
}
:deep(textarea) {
  min-height: 145px !important;
}
.dzz-pdf-dialog-content-container {
  margin-top: -10px;
  margin-bottom: -10px;
}
.dzz-pdf-dialog-close {
  position: absolute;
  right: -1px;
  top: 0;
  z-index: 1;
  cursor: pointer;
}
.dzz-pdf-btn-box {
  display: flex;
  justify-content: space-between;
  margin-top: 10px
}
</style>
<style>
.no-header-dialog .el-dialog__header{
  display: none !important;
}
</style>
